import * as mqtt from 'mqtt/dist/mqtt.min'

var client: any

var messageCallback = (topic: string, message: any, packet: any) => {

}

function init(clientId: string, username: string, password: string) {
  client = mqtt.connect('wss://duofutan.com/mqtt', {
    clientId: clientId,
    username: username,
    password: password,
    keepalive: 30,
    clean: true,
    reconnectPeriod: 15*1000, // 10s
    connectTimeout: 20*1000,  // 20s
  })

  client.on('connect', function () {
    console.log('connect')
    /*client.subscribe('/fruit', function (e) {
      if (!e) {
        client.publish('fruit', 'mango')
      }
    })*/
  })

  client.on('error', function (error: any) {
    console.log('error', error)
  })

  client.on('reconnect', function () {
    console.log('reconnect')
  })

  client.on('message', function (topic: any, message: any, packet: any) {
    //console.log(topic, message.toString())
    //client.end()
    const ss = topic.split('/')
    console.log(ss, message)

    messageCallback(topic, message, packet)
  })
}

function setMessageCallback(callback: any) {
  messageCallback = callback
}


export {
  client as mqttClient,
  init as mqttInit,
  setMessageCallback as mqttSetMessageCallback,
}
