/** 
 *  @author 
 * @description  按需引入Vant
*/ 
import {
    Button, Tabbar,
    TabbarItem, Popup,Overlay,
    Calendar, Cell, 
    CellGroup, 
    Image as VanImage,Col, 
    Row,Grid, GridItem,
    Stepper,Icon,
    NavBar,ContactList,
    ContactEdit,Dialog,
    Tab, Tabs,Empty,
    AddressList,AddressEdit,
    DropdownMenu,DropdownItem,
    Pagination,Card,
    Checkbox, CheckboxGroup,
    Form, Field,NoticeBar,
    Switch,Picker,Uploader      
      } from 'vant'
  const  pluginsVant = [
    Button, 
    Tabbar,
    TabbarItem,
    Popup,Overlay,
    Calendar,
    Cell,
    CellGroup,
    VanImage,
    Col, Row,
    Grid, GridItem,
    Stepper,
    Icon,
    NavBar,ContactList,
    ContactEdit,Dialog,
    Tab, Tabs,Empty,
    AddressList,AddressEdit,
    DropdownMenu,DropdownItem,
    Pagination,Card ,
    Checkbox, CheckboxGroup,
    Form, Field,NoticeBar,
    Switch,Picker,Uploader   
  ]
  export const vantPlugins = {
    install: function(vm:any) {
      pluginsVant.forEach(item => {
        vm.component(item.name, item);
      });
    }
  };