import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import ('./../layout/index.vue'),
    redirect: '/home',
    meta: {
      title: '首页',
      keepAlive:false
    },
    children: [
      {
        path: '/home',
        name: 'HomeView',
        component: () => import('@/views/HomeView.vue'),
        meta: {
          title: "首页"
        }
      },
      {
        path: '/menu',
        name: 'MenuView',
        component: () => import('@/views/MenuView.vue'),
        meta: {
          title: "流程"
        }
      },
      {
        path: '/about',
        name: 'AboutView',
        component: () => import('@/views/AboutView.vue'),
        meta: {
          title: "我的"
        },
      },
    ]
  },
  {
    path: '/accoutManage',
    name: 'accoutManage',
    component: () => import('@/views/about/accoutManage.vue'),
    meta: {
      title: "账号管理"
    }
  },
  {
    path: '/addAccout',
    name: 'addAccout',
    component: () => import('@/views/about/addAccout.vue'),
    meta: {
      title: "添加账号"
    }
  },
  {
    path: '/myCash',
    name: 'myCash',
    component: () => import('@/views/about/myCash.vue'),
    meta: {
      title: "我的钱包"
    }
  },
  {
    path: '/cashRecord',
    name: 'cashRecord',
    component: () => import('@/views/about/cashRecord.vue'),
    meta: {
      title: "提现记录"
    }
  },
  {
    path: '/addressManage',
    name: 'addressManage',
    component: () => import('@/views/about/addressManage.vue'),
    meta: {
      title: "地址管理"
    }
  },
  {
    path: '/newAddress',
    name: 'newAddress',
    component: () => import('@/views/about/newAddress.vue'),
    meta: {
      title: "新增地址"
    }
  },  
  {
    path: '/editAddress',
    name: 'editAddress',
    component: () => import('@/views/about/editAddress.vue'),
    meta: {
      title: "编辑地址"
    }
  },
  {
    path: '/shopList',
    name: 'shopList',
    component: () => import('@/views/home/shopList.vue'),
    meta: {
      title: "店铺列表"
    }
  },
  {
    path: '/deviceList',
    name: 'deviceList',
    component: () => import('@/views/home/deviceList.vue'),
    meta: {
      title: "设备列表"
    }
  },
  {
    path: '/deviceDetail',
    name: 'deviceDetail',
    component: () => import('@/views/home/deviceDetail.vue'),
    meta: {
      title: "设备详情"
    }
  },
  {
    path: '/shopMange',
    name: 'shopMange',
    component: () => import('@/views/home/device/shopMange.vue'),
    meta: {
      title: "货道管理"
    }
  },
  {
    path: '/shopRoad',
    name: 'shopRoad',
    component: () => import('@/views/home/device/shopRoad.vue'),
    meta: {
      title: "开关货道"
    }
  },
  {
    path: '/editDevice',
    name: 'editDevice',
    component: () => import('@/views/home/device/editDevice.vue'),
    meta: {
      title: "设备修改"
    }
  },
  {
    path: '/setParam',
    name: 'setParam',
    component: () => import('@/views/home/device/setParam.vue'),
    meta: {
      title: "参数设置"
    }
  },
  {
    path: '/deviceReg',
    name: 'deviceReg',
    component: () => import('@/views/home/deviceReg.vue'),
    meta: {
      title: "设备注册"
    }
  },  
  {
    path: '/shopManage',
    name: 'shopManage',
    component: () => import('@/views/home/shopManage.vue'),
    meta: {
      title: "商品管理"
    }
  },  
  {
    path: '/addShop',
    name: 'addShop',
    component: () => import('@/views/home/shop/addShop.vue'),
    meta: {
      title: "商品上架"
    }
  }, 
  {
    path: '/shopType',
    name: 'shopType',
    component: () => import('@/views/home/shop/shopType.vue'),
    meta: {
      title: "商品类别"
    }
  }, 
  {
    path: '/editShop',
    name: 'editShop',
    component: () => import('@/views/home/shop/editShop.vue'),
    meta: {
      title: "商品修改"
    }
  }, 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
