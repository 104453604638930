import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'lib-flexible'
import 'amfe-flexible'
// 全局引入按需引入UI库 vant
import { vantPlugins } from './plugins/vant'
// 引入全局样式
import '@/assets/scss/index.scss';
import 'vant/lib/index.css';

import { Area } from 'vant';



// titile根据页面改变
router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title as string
  }
  next()
})
createApp(App).use(store).use(router).use(vantPlugins).use(Area).mount('#app')
